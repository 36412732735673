import config from './config';
import {HsbProject} from "hsbshareviewer"

const axios = require('axios').default;

const automationTemplate = {
    WorkItem: {
        Id: "",
        ActivityId: "",
        Arguments: {
            InputArguments: [],
            OutputArguments: []
        }
    },
    Activity: {
        Id: "",
        HostApplication: " ",
        Parameters: {
            InputParameters: [],
            OutputParameters: [],
        },
        Instruction: {
            CommandLineParameters: []
        }
    }
}

const AutomationService = {
    addModelAutomationService: async (hsbProject: HsbProject, modelName: string, version: string, file: File, resource: string, token: string, bim360?: boolean): Promise<any> => {
        try {
            let template = automationTemplate;
            template.WorkItem.Id = "hsbTranslationWorkItem";
            template.WorkItem.ActivityId = "hsbTranslatorActivity";
            template.WorkItem.Arguments.InputArguments = [{
                Resource: resource,
                Name: "TranslationFile",
                StorageProvider: bim360 ? "Bim360" : "HsbShare",
                HostServer: config.getHostName(config.server),
                Headers: [{
                    Name: bim360 ? "Authorization" : "AccessToken",
                    Value: token
                }]
            }];
            template.WorkItem.Arguments.OutputArguments = [{
                StorageProvider: "None"
            }];
            template.Activity.Id = "hsbTranslatorActivity";
            template.Activity.HostApplication = "$(__applicationsRoot)/hsbcad/Branch_26/hsbShare/hsbShareConsole.exe";
            template.Activity.Parameters.InputParameters = [{
                Name: "TranslationFile",
                LocalFolderPath: "$(__config)/hsbShare/Translation/TempData/Download/Input",
                LocalFileName: file.name
            }];
            template.Activity.Parameters.OutputParameters = [{
                Name: "Node",
                LocalFolderPath: " ",
                LocalFileName: " "
            }];
            template.Activity.Instruction.CommandLineParameters = [
                "/hostServer:" + config.getHostName(config.server),
                "/token:" + token,
                "/projectID:" + hsbProject.projectID,
                `/modelFilePath:"$(TranslationFile)"`,
                `/modelName:"${modelName}"`,
                "/overwriteExistingModel:0",
                "/modelVersion:" + version];

            return await axios.post(`${config.automation}/api/v2/io/execution/context`, template)
        }
        catch (err) {
            return Promise.reject(err);
        }
    },
    createModelThumbnailsAutomationService: async (hsbProject: HsbProject, modelId: string, token: string): Promise<any> => {
        try {

            let template = automationTemplate;
            template.WorkItem.Id = "hsbThumbnailGeneratorItem";
            template.WorkItem.ActivityId = "hsbThumbnailGeneratorActivity";
            template.WorkItem.Arguments.InputArguments = [{
                StorageProvider: "None"
            }];
            template.WorkItem.Arguments.OutputArguments = [{
                StorageProvider: "None"
            }];
            template.Activity.Id = "hsbThumbnailGeneratorActivity";
            template.Activity.HostApplication = "$(__applicationsRoot)/hsbcad/Default/hsbShare/hsbshareWebEngine.exe";
            template.Activity.Parameters.InputParameters = [{
                Name: "None",
                LocalFolderPath: " ",
                LocalFileName: " "
            }];
            template.Activity.Parameters.OutputParameters = [{
                Name: "Node",
                LocalFolderPath: " ",
                LocalFileName: " "
            }];
            template.Activity.Instruction.CommandLineParameters = [
                "/hostServer:" + config.getHostName(config.server),
                "/token:" + token,
                "/projectId:" + hsbProject.projectID,
                "/modelId:" + modelId];

            return await axios.post(`${config.automation}/api/v2/io/execution/context`, template)
        }
        catch (err) {
            return Promise.reject(err);
        }
    }
}
export default AutomationService;
